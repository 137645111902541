import omeletesGrandes from "../../assets/omeletesGrandes.jpg";
import espaguete from "../../assets/v2/espaguetes.jpeg";
import lanches from "../../assets/v2/hamburguer.jpeg";
import hotDogs from "../../assets/v2/hotDogs.jpeg";
import lanchesGourmet from "../../assets/v2/lancheGourmet.jpeg";
import lanchesComContraFile from "../../assets/v2/lanchesComContraFile.jpeg";
import mexidos from "../../assets/v2/mexidos.jpeg";
import pizzasBrotinho from "../../assets/v2/pizzaBrotinho.jpeg";
import pizzasFamilia from "../../assets/v2/pizzasFamilia.jpeg";
import porcoes from "../../assets/v2/porcoes.jpeg";
import pratosEspeciais from "../../assets/v2/pratosEspeciais.jpg";
import saladasEGrelhados from "../../assets/v2/saladasEGrelhados.jpeg";
import sobremesas from "../../assets/v2/sobremesas.jpeg";
import { ISlides } from "./ImageSlider";

export const SliderData = [
  {
    image: lanchesGourmet,
    type: "lanchesGourmet",
  },
  {
    image: espaguete,
    type: "espaguete",
  },
  {
    image: lanches,
    type: "lanches",
  },
  {
    image: hotDogs,
    type: "hotDogs",
  },
  {
    image: lanchesComContraFile,
    type: "lanchesComContraFile",
  },
  {
    image: pizzasBrotinho,
    type: "pizzasBrotinho",
  },
  {
    image: pizzasFamilia,
    type: "pizzasFamilia",
  },
  {
    image: saladasEGrelhados,
    type: "saladasEGrelhados",
  },
  {
    image: pratosEspeciais,
    type: "pratosEspeciais",
  },
  {
    image: mexidos,
    type: "mexidos",
  },
  {
    image: omeletesGrandes,
    type: "omeletesGrandes",
  },
  {
    image: sobremesas,
    type: "sobremesas",
  },
  {
    image: porcoes,
    type: "porcoes",
  },
] as ISlides[];
