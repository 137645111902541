const returnNameOfType = (type: string) => {
  switch (type) {
    case "lanchesGourmet":
      return "Lanches Gourmet";
    case "espaguete":
      return "Espaguetes";
    case "lanches":
      return "Lanches";
    case "hotDogs":
      return "HotDogs";
    case "lanchesComContraFile":
      return "Lanches com Contra Filé";
    case "pizzasBrotinho":
      return "Pizzas Brotinho";
    case "pizzasFamilia":
      return "Pizzas FamÍlia";
    case "saladasEGrelhados":
      return "Saladas e Grelhados";
    case "pratosEspeciais":
      return "Pratos Especiais";
    case "mexidos":
      return "Mexidos";
    case "omeletesGrandes":
      return "Omeletes";
    case "sobremesas":
      return "Sobremesas";
    case "porcoes":
      return "Porções";
    default:
      return null;
  }
};

export { returnNameOfType };
