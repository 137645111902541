import { transparentize, darken } from "polished";
import Modal from "react-modal";
import styled, { css } from "styled-components";

const primary = "#FFF";
const secondary = "#FF8A00";
const backgroundColor = "#000";

interface IListProductBanner {
  image: string;
}

export const BackgroundContent = styled.div`
  height: 100%;
  width: 100%;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 1440px) {
    width: 1440px;
  }
  .menu-content {
    padding: 3rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: ${transparentize(0.2, backgroundColor)};
    @media (max-height: 710px) {
      padding: 1rem;
    }

    h1 {
      font-size: 3rem;
      color: var(--white);
      @media (max-width: 710px) {
        text-align: center;
        font-size: 2rem;
      }
    }

    a {
      align-self: flex-end;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      text-decoration: none;
      color: var(--white);
      width: 250px;
      height: 80px;
      font-size: 2rem;
      font-weight: bold;
      border: 0;
      padding: 1rem;
      border-radius: 50px;
      background-color: var(--orange);
      transition: 0.4s all;
      &:hover {
        background-color: ${darken(0.15, secondary)};
      }
      @media (max-width: 710px) {
        /* width: 130px;
        height: 70px;
        font-size: 1.5rem; */
      }
      @media (max-height: 710px) {
        /* height: 60px; */
      }
    }
  }
`;

export const CustomModal = styled(Modal)``;

export const CointainerList = styled.div<IListProductBanner>`
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  background-image: ${({ image }) => css`url(${image})`};
  background-size: cover;
`;
export const ListProductBanner = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  padding: 1rem;
  background-color: ${transparentize(0.2, primary)};
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;

export const Product = styled.div`
  padding: 1rem;
  margin-bottom: 0.6rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 4px dotted black;

  .product-name {
    width: 60%;
  }

  h1 {
    font-weight: 500;
    font-size: 1.5rem;
    color: var(--black-900);
  }
  span {
    font-weight: 500;
    font-size: 1.4rem;
    color: var(--black-900);
  }
`;
