import { useState, Dispatch, SetStateAction } from "react";
import { BsArrowLeftSquareFill, BsArrowRightSquareFill } from "react-icons/bs";

import { returnNameOfType } from "./returnNameOfType";
import { SliderData } from "./SliderData";
import { ContainerSlide } from "./styles";

export interface ISlides {
  image: string;
  type:
    | "lanchesGourmet"
    | "espaguete"
    | "hotDogs"
    | "lanches"
    | "lanchesComContraFile"
    | "mexidos"
    | "omeletesGrandes"
    | "pizzasBrotinho"
    | "pizzasFamilia"
    | "porcoes"
    | "pratosEspeciais"
    | "saladasEGrelhados"
    | "sobremesas";
}

interface IArraySlides {
  slides: ISlides[];
  setSelected: Dispatch<
    SetStateAction<
      | "lanchesGourmet"
      | "espaguete"
      | "hotDogs"
      | "lanches"
      | "lanchesComContraFile"
      | "mexidos"
      | "omeletesGrandes"
      | "pizzasBrotinho"
      | "pizzasFamilia"
      | "porcoes"
      | "pratosEspeciais"
      | "saladasEGrelhados"
      | "sobremesas"
    >
  >;
  openModal: boolean;
  handleOpenModal: Dispatch<SetStateAction<boolean>>;
  setSelectedBackround: Dispatch<SetStateAction<string>>;
}

const ImageSlider = ({
  slides,
  setSelected,
  openModal,
  handleOpenModal,
  setSelectedBackround,
}: IArraySlides) => {
  const [current, setCurrent] = useState(0);
  const { length } = slides;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }

  const handleSelectOption = (slide: ISlides) => {
    setSelected(slide.type);
    setSelectedBackround(slide.image);
    handleOpenModal(!openModal);
  };

  return (
    <ContainerSlide>
      <div className="desktop-selects">
        <BsArrowLeftSquareFill className="left-arrow" onClick={prevSlide} />
      </div>

      {SliderData.map((slide: ISlides, index: any) => {
        return (
          <div
            className={index === current ? "slide active" : "slide"}
            key={index}
            onClick={() => handleSelectOption(slide)}
          >
            {index === current && (
              <>
                <img src={slide.image} alt="travel image" className="image" />
                <div className="type_item">
                  <span>{returnNameOfType(slide.type)}</span>
                </div>
              </>
            )}
          </div>
        );
      })}
      <div className="desktop-selects">
        <BsArrowRightSquareFill className="right-arrow" onClick={nextSlide} />
      </div>

      <div className="mobile-selects">
        <BsArrowLeftSquareFill
          className="mobile-left-arrow"
          onClick={prevSlide}
        />
        <BsArrowRightSquareFill
          className="mobile-right-arrow"
          onClick={nextSlide}
        />
      </div>
    </ContainerSlide>
  );
};

export default ImageSlider;
