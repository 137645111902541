import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import { About } from "../../components/sections/About";
import { Address } from "../../components/sections/Address";
import { Apresentation } from "../../components/sections/Apresentation";
import { Menu } from "../../components/sections/Menu";

import "aos/dist/aos.css";

export function Welcome() {
  return (
    <>
      <Header />
      <Apresentation />
      <Menu />
      <About />
      <Address />
      <Footer />
    </>
  );
}
