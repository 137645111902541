import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`


  :root {
    --black-1000: #000000;
    --black-900: #0A0804;
    --text-title: #363f5f;
    --text-body: #969cb3;
    --grey-100: #9D9D9D;
    --grey-300: #B7AEAE;
    --grey-1000: #222222;
    --white: #fff;
    --white20: #D9D9D9;
    --red: #C51515;
    --orange: #FF8A00;
  }

  ::-webkit-scrollbar {
    width: 15px;
  }

  ::-webkit-scrollbar-track {
    background: transparent; 
  }
 
  ::-webkit-scrollbar-thumb {
    background: #ddd;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #ccc; 
  }

  html{
    scroll-behavior: smooth;
    @media (max-width: 1080px){
      font-size: 93.75%;
    }
    @media (max-width: 720px){
      font-size: 87.5%;
    }
  }
  
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body{
    /* background-color: var(--black-900); */
    background-color: var(--grey-1000);
    -webkit-font-smoothing: antialiased;

  }
  
  body,input,textarea,button{
    font-family:'Poppins', sans-serif;
    font-weight: 400;
  }
  h1,h2,h3,h4,h5,h6,strong{
    font-weight: 600;
  }
  button{
    cursor: pointer;
  }
 
  .react-modal-overlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
  }



  .react-modal-content {
    height: 60vh;
    width: 600px; 
    outline: 0;
    background: var(--white);
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    transition: 0.3s all;
  }
  @media (max-width: 900px){
    .react-modal-content{
      width: 500px; 
      height: 500px;
    }
  }
  @media (max-width: 700px){
    .react-modal-content{
      width: 400px; 
      height: 400px;
    }
  }
  @media (max-width: 550px){
    .react-modal-content{
      width: 320px; 
      height: 400px;
    }
  }
  @media (max-width: 400px){
    .react-modal-content{
      width: 280px; 
      height: 400px;
    }
  }
`;
