import imgBackground from "../../../assets/backgroundAbout.png";
import mobileImgBackground2 from "../../../assets/backgroundAbout2.png";
import { AlignSection } from "../../AlignSection";
import { BackgroundContent } from "./styles";

export function About() {
  return (
    <AlignSection
      id="about"
      image={imgBackground}
      secondImage={mobileImgBackground2}
    >
      <BackgroundContent>
        <div className="menu-content">
          <h1>Um pouco sobre nós</h1>
          <div data-aos="fade-up" data-aos-duration="1500">
            <p>
              Iniciamos nossa história em 1 de Abril de 2015 pautados na
              qualidade, bom atendimento e agilidade. Pilares estes que nos
              consolidaram no mercado regional como uma lanchonete inovadora e
              com lanches diferenciados. Nossa proposta desde o início é
              entregar além do que o cliente precisa, proporcionando uma
              experiência única de sabor em cada combinação de nosso exclusivo
              cardápio. Hoje continuamos a escrever nossa história no bairro
              Bico Doce com a nossa primeira unidade aberta, e seguimos gratos
              por todos os clientes que nos acompanham por onde quer que
              passemos.
            </p>
          </div>
        </div>
      </BackgroundContent>
    </AlignSection>
  );
}
