import { Dispatch, SetStateAction, useState } from "react";
import { RiMenu3Fill } from "react-icons/ri";

import Logo from "../../../assets/logo.png";
import { Container, OptionMenuContainer } from "./styles";

interface IHeaderProps {
  setMenuIsVisible: Dispatch<SetStateAction<boolean>>;
}

interface IOptionMenuProps {
  name: string;
  path?: string;
}

export function MenuDesktop({ setMenuIsVisible }: IHeaderProps) {
  const [selected, setSelected] = useState("Início");

  const OptionMenu = ({ name, path }: IOptionMenuProps) => {
    return (
      <OptionMenuContainer selected={selected} name={name}>
        <a href={`#${path}`} onClick={() => setSelected(name)}>
          {name}
        </a>
        <div />
      </OptionMenuContainer>
    );
  };

  return (
    <Container>
      <div className="content">
        <div className="content-header">
          <img
            className="logo"
            src={Logo}
            data-aos="fade-down"
            data-aos-duration="1000"
          />
          <nav>
            <OptionMenu name="Início" path="home" />
            <OptionMenu name="Cardápio" path="menu" />
            <OptionMenu name="Sobre" path="about" />
            <OptionMenu name="Endereço" path="address" />
            <a
              target="_blank"
              href="https://wa.me/553237217795?text=Gostaria%20de%20realizar%20meu%20pedido!"
              className="button-contact-us"
            >
              Peça já
            </a>
          </nav>
        </div>
        <RiMenu3Fill
          onClick={() => setMenuIsVisible(true)}
          className="mobile"
          color="white"
          size={45}
        />
      </div>
    </Container>
  );
}
