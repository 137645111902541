import styled, { css } from "styled-components";

interface ISectionContainerImageBackground {
  image?: string;
  secondImage?: string;
}
interface ISectionContent {
  contentColor?: string;
}

export const SectionContainer = styled.section`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  transition: 0.3s all;
`;

export const SectionContainerImageBackground = styled.div<ISectionContainerImageBackground>`
  width: 1440px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  transition: 0.3s all;
  ${({ image }) =>
    image !== ""
      ? css`
          background-image: url(${image});
        `
      : null}
  @media (min-width: 1440px) {
    width: 1440px;
  }
  @media (max-width: 770px) {
    ${({ secondImage }) =>
      secondImage !== ""
        ? css`
            background-image: url(${secondImage});
          `
        : null};
    background-color: white;
  }
`;

export const SectionContent = styled.section<ISectionContent>`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ contentColor }) => contentColor};
`;
