import json from "../../../../assets/data.json";

interface IReturnData {
  selected:
    | "lanchesGourmet"
    | "espaguete"
    | "hotDogs"
    | "lanches"
    | "lanchesComContraFile"
    | "mexidos"
    | "omeletesGrandes"
    | "pizzasBrotinho"
    | "pizzasFamilia"
    | "porcoes"
    | "pratosEspeciais"
    | "saladasEGrelhados"
    | "sobremesas"
    | undefined
    | string;
}

export const returnDataSelectedInMoDal = ({ selected }: IReturnData) => {
  switch (selected) {
    case "lanchesGourmet":
      return json.lanchesGourmet;
    case "espaguete":
      return json.espaguete;
    case "hotDogs":
      return json.hotDogs;
    case "lanches":
      return json.lanches;
    case "lanchesComContraFile":
      return json.lanchesComContraFile;
    case "mexidos":
      return json.mexidos;
    case "omeletesGrandes":
      return json.omeletesGrandes;
    case "pizzasBrotinho":
      return json.pizzasBrotinho;
    case "pizzasFamilia":
      return json.pizzasFamilia;
    case "porcoes":
      return json.porcoes;
    case "pratosEspeciais":
      return json.pratosEspeciais;
    case "saladasEGrelhados":
      return json.saladasEGrelhados;
    case "sobremesas":
      return json.sobremesas;
    default:
      return null;
  }
};
