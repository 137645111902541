import InstagramIcon from "../../assets/Instagram.svg";
import NumberIcon from "../../assets/Number.svg";
import { ContainerFooter } from "./styles";

export const Footer = () => {
  return (
    <ContainerFooter>
      <div className="content">
        <div>
          <a
            target="_blank"
            href="https://instagram.com/tudaochefsfood?igshid=YmMyMTA2M2Y="
          >
            <img src={InstagramIcon} />
          </a>
          <a
            target="_blank"
            href="https://wa.me/553237217795?text=Gostaria%20de%20realizar%20meu%20pedido!"
          >
            <img src={NumberIcon} />
          </a>
        </div>
        <a
          target="_blank"
          href="https://www.instagram.com/severus.tech/?igshid=YmMyMTA2M2Y%3D"
        >
          <span>©2022Copyright: SeverusTech</span>
        </a>
      </div>
    </ContainerFooter>
  );
};
