import imgBackground from "../../../assets/address_background.png";
import mobileImgBackground from "../../../assets/address_background2.png";
import Location from "../../../assets/location.svg";
import { AlignSection } from "../../AlignSection";
import { BackgroundContent } from "./styles";

export function Address() {
  const LocationButton = () => {
    return (
      <a
        className="location-button"
        target="_blank"
        href="https://www.google.com/maps/place/Tudão+Chefs+Food/@-21.1327929,-42.3877134,14z/data=!4m19!1m13!4m12!1m4!2m2!1d-42.3643235!2d-21.1270552!4e1!1m6!1m2!1s0xbcc63f5f265b4b:0x46221c7ff38e5e02!2studao+chefs+localização!2m2!1d-42.3733156!2d-21.1498873!3m4!1s0xbcc63f5f265b4b:0x46221c7ff38e5e02!8m2!3d-21.1498873!4d-42.3733156"
      >
        <img src={Location} />
        <div className="line-up"></div>
        <div className="description-location">
          <p>
            Rua:
            <b> Vicente Alves</b>
          </p>
          <p>
            N°:
            <b> 323</b>
          </p>
          <p>
            Bairro:
            <b> Bico Doce</b>
          </p>
        </div>
      </a>
    );
  };

  return (
    <AlignSection
      id="address"
      image={imgBackground}
      secondImage={mobileImgBackground}
    >
      <BackgroundContent>
        <h1>Onde nos encontrar?</h1>
        <LocationButton />
        <div></div>
      </BackgroundContent>
    </AlignSection>
  );
}
