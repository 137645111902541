import { transparentize, darken } from "polished";
import styled from "styled-components";

const secondary = "#fff";
const backgroundColor = "#000";

export const BackgroundContent = styled.section`
  height: 100%;
  background-size: cover;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s all;
  @media (min-width: 1440px) {
    width: 1440px;
  }
  .menu-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    transition: 0.3s all;
    h1 {
      margin-top: 3rem;
      font-size: 3rem;
      color: var(--white);
      @media (max-width: 900px) {
        font-size: 2.5rem;
      }
      @media (max-width: 480px) {
        text-align: center;
      }
      @media (max-width: 380px) {
        font-size: 2rem;
      }
    }

    a {
      text-align: center;
      text-decoration: none;
      color: var(--black);
      width: 250px;
      height: 60px;
      mix-blend-mode: screen;
      border: 0;
      padding: 1rem;
      border-radius: 2rem;
      background-color: var(--white20);
      transition: 0.3s all;

      &:hover {
        background-color: ${darken(0.15, secondary)};
      }
    }

    div {
      margin-bottom: 4%;
      background-color: ${transparentize(0.85, backgroundColor)};
      padding: 2rem;
      align-self: flex-end;
      width: 85%;
      transition: 0.3s all;
      @media (max-width: 690px) {
        width: 100%;
        margin-bottom: 0;
        align-self: center;
        background-color: ${transparentize(0.45, backgroundColor)};
      }
      p {
        font-size: 2rem;
        text-align: right;
        color: var(--white);
        font-weight: bold;
        transition: 0.3s all;
        @media (max-width: 1300px) {
          font-size: 1.5rem;
        }
        @media (max-width: 480px) {
          font-size: 1.1rem;
        }
      }
    }
  }
`;
