import { ReactNode } from "react";

import {
  SectionContainer,
  SectionContainerImageBackground,
  SectionContent,
} from "./styles";

interface IAlignSection {
  image?: string;
  secondImage?: string;
  children: ReactNode;
  id?: string;
  contentColor?: string;
}

export const AlignSection = ({
  children,
  image,
  secondImage,
  id,
  contentColor,
}: IAlignSection) => {
  return (
    <SectionContainer id={id}>
      <SectionContainerImageBackground image={image} secondImage={secondImage}>
        <SectionContent contentColor={contentColor}>{children}</SectionContent>
      </SectionContainerImageBackground>
    </SectionContainer>
  );
};
