import { BrowserRouter, Routes, Route } from "react-router-dom";

import { NotFound } from "../pages/NotFound";
import { Welcome } from "../pages/Welcome";

export const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Welcome />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};
