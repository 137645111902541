import { useEffect, useState } from "react";

import imgBackground from "../../../assets/backgroundMenu.png";
import mobileImgBackground from "../../../assets/backgroundMenu2.png";
import { IProduct } from "../../../types/Product";
import { AlignSection } from "../../AlignSection";
import ImageSlider from "../../MenuSelectOption/ImageSlider";
import { SliderData } from "../../MenuSelectOption/SliderData";
import {
  BackgroundContent,
  CointainerList,
  CustomModal,
  ListProductBanner,
  Product,
} from "./styles";
import "./styles.css";
import { returnDataSelectedInMoDal } from "./utils/returnDataSelectedInModal";

export function Menu() {
  const [selected, setSelected] = useState<
    | "lanchesGourmet"
    | "espaguete"
    | "hotDogs"
    | "lanches"
    | "lanchesComContraFile"
    | "mexidos"
    | "omeletesGrandes"
    | "pizzasBrotinho"
    | "pizzasFamilia"
    | "porcoes"
    | "pratosEspeciais"
    | "saladasEGrelhados"
    | "sobremesas"
  >("lanchesGourmet");
  const [selectedBackround, setSelectedBackround] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [data, setData] = useState<IProduct[]>([]);

  const handleOpenModal = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (selected !== null) {
      const products = returnDataSelectedInMoDal({ selected }) as IProduct[];
      setData(products);
    }
  }, [selected]);

  const ListOfProducts = () => {
    console.log(data);
    return (
      <CustomModal
        overlayClassName="react-modal-overlay"
        className="react-modal-content"
        ariaHideApp={false}
        isOpen={isOpen}
        onRequestClose={handleOpenModal}
        closeTimeoutMS={400}
      >
        <CointainerList image={selectedBackround}>
          <ListProductBanner>
            {data.map((product, index) => {
              const item = product;
              return (
                <Product key={index}>
                  <div className="product-name">
                    <h1>{item.name}</h1>
                  </div>
                  {/* <span className="product-price">
                    {item.price.toFixed(2).replace(".", ",")}
                  </span> */}
                </Product>
              );
            })}
          </ListProductBanner>
        </CointainerList>
      </CustomModal>
    );
  };

  return (
    <AlignSection
      id="menu"
      image={imgBackground}
      secondImage={mobileImgBackground}
    >
      <BackgroundContent>
        <div className="menu-content">
          <h1>Nosso Cardápio</h1>
          <ImageSlider
            openModal={isOpen}
            slides={SliderData}
            setSelected={setSelected}
            handleOpenModal={handleOpenModal}
            setSelectedBackround={setSelectedBackround}
          />
          <ListOfProducts />
          <a
            target="_blank"
            href="https://wa.me/553237217795?text=Gostaria%20de%20realizar%20meu%20pedido!"
          >
            Peça já
          </a>
        </div>
      </BackgroundContent>
    </AlignSection>
  );
}
