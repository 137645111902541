import { darken, transparentize } from "polished";
import styled from "styled-components";

const backgroundColor = "#C51515";
const primary = "#FFF";

export const BackgroundContent = styled.section`
  background-color: ${transparentize(0.2, backgroundColor)};
  height: 100%;
  width: 100%;
  padding: 4rem;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  @media (min-width: 1440px) {
    width: 1440px;
  }
  @media (max-width: 520px) {
    padding: 2rem;
  }

  h1 {
    font-size: 3rem;
    text-align: center;
    color: var(--white);
    @media (max-width: 680px) {
      font-size: 2rem;
    }
  }

  .location-button {
    width: 500px;
    height: 160px;
    border-radius: 100px;
    text-decoration: none;
    background-color: var(--orange);
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: 0.3s all;
    @media (max-width: 680px) {
      width: 400px;
    }
    @media (max-width: 520px) {
      width: 300px;
      height: 100px;
    }
    @media (max-width: 420px) {
      width: 240px;
    }
    @media (max-width: 360px) {
      width: 80%;
      height: 50px;
    }
    img {
      padding: 2rem;
      transition: 0.3s all;
      @media (max-width: 420px) {
        width: 6rem;
        padding: 1.6rem;
      }
      @media (max-width: 360px) {
        width: 4.5rem;
        padding: 1.4rem;
      }
    }
    .line-up {
      height: 100%;
      width: 3px;
      background-color: ${transparentize(0.8, primary)};
    }
    .description-location {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 1rem;
      p {
        text-align: left;
        color: var(--white);
        font-size: 1.6rem;
        font-weight: bold;
        transition: 0.3s all;
        @media (max-width: 520px) {
          font-size: 1rem;
        }
        @media (max-width: 420px) {
          font-size: 0.8rem;
        }
        @media (max-width: 360px) {
          font-size: 0.5rem;
        }
      }
      b {
        font-weight: 300;
      }
    }
  }
`;
