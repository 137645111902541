import { transparentize } from "polished";
import styled from "styled-components";

interface IOptionMenu {
  selected: string;
  name: string;
}

const backgroundColor = "#020202";
const buttonColor = "#C51515";

export const Container = styled.header`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* color: var(--white); */
  position: fixed;
  position: absolute;
  z-index: 10;
  .button-contact-us {
    text-decoration: none;
    border: 0;
    border-radius: 25px;
    height: 50px;
    width: 120px;
    display: flex;
    font-size: 0.8rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 1.5rem;
    padding: 0.4rem;
    background-color: var(--red);
    color: white;
    transition: 0.4s all;
    &:hover {
      background-color: ${transparentize(0.4, buttonColor)};
    }
  }
  .content {
    display: flex;
    padding: 2rem;
    width: 100%;
    background-color: ${transparentize(0.3, backgroundColor)};
    align-items: center;
    justify-content: center;
    @media (min-width: 1440px) {
      width: 1440px;
    }
    .content-header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      img {
        transition: 0.3s all;
        width: 200px;
        @media (max-width: 720px) {
          width: 150px;
        }
        @media (max-width: 490px) {
          width: 120px;
        }
      }
    }
    nav {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }

  .mobile {
    display: none;
  }

  @media (max-width: 900px) {
    .mobile {
      margin: 2rem;
      display: initial;
    }
    .content {
      nav {
        display: none;
      }
    }
  }
  @media (max-width: 720px) {
    .banner {
      height: 7rem;
    }
  }
`;

export const OptionMenuContainer = styled.div<IOptionMenu>`
  width: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 1.5rem;
  a {
    cursor: pointer;
    text-decoration: none;
    color: var(--white);
  }

  div {
    top: 1rem;
    position: relative;
    background-color: ${({ selected, name }) => selected === name && "white"};
    height: 3px;
    width: 100%;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  @media (max-width: 980px) {
    width: 100px;
  }
`;
