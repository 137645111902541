import styled from "styled-components";

export const ContainerFooter = styled.footer`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  .content {
    padding: 3rem;
    width: 1440px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--black-1000);
    div {
      width: 200px;
      margin-bottom: 1rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      img {
        transition: 0.3s all;
        @media (max-width: 700px) {
          width: 2rem;
        }
      }
    }
    span {
      color: var(--white);
      font-size: 2rem;
      font-weight: bold;
      text-align: center;
      transition: 0.3s all;
      @media (max-width: 700px) {
        font-size: 1.5rem;
      }
    }
    a {
      text-decoration: none;
    }
  }
`;
