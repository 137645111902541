import { darken } from "polished";
import styled from "styled-components";

export const NotFoundCotnainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--black-900);

  h1 {
    color: var(--white);
    text-align: center;
    font-size: 4.6rem;
    transition: 0.3s all;
  }

  p {
    text-align: center;
    color: var(--white);
    transition: 0.3s all;
  }

  button {
    transition: 0.3s all;
    border: 0;
    margin-top: 1rem;
    padding: 1rem;
    height: 50px;
    width: 100px;
    border-radius: 25px;
    &:hover {
      background-color: ${darken(0.2, "white")};
    }
  }
`;
