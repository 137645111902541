import { useState } from "react";

import { MenuDesktop } from "./MenuDesktop";
import { MenuMobile } from "./MenuMobile";

export const Header = () => {
  const [menuIsVisible, setMenuIsVisible] = useState(false);

  return (
    <>
      <MenuMobile
        menuIsVisible={menuIsVisible}
        setMenuIsVisible={setMenuIsVisible}
      />
      <MenuDesktop setMenuIsVisible={setMenuIsVisible} />
    </>
  );
};
