import { darken, transparentize } from "polished";
import styled from "styled-components";

const backgroundColor = "#0A0804";
const secondary = "#D9D9D9";

export const ContentApresentation = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;

  .message-content {
    transition: 0.3s all;
    margin-bottom: 3%;
    padding: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: ${transparentize(0.85, backgroundColor)};
    width: 950px;

    h1 {
      font-size: 3rem;
      color: var(--white);
    }
    a {
      transition: 0.7s all;
      margin-top: 1rem;
      text-align: center;
      text-decoration: none;
      color: var(--black);
      width: 200px;
      mix-blend-mode: screen;
      border: 0;
      padding: 1rem;
      border-radius: 2rem;
      background-color: var(--white20);
      transition: 0.4s all;
      &:hover {
        background-color: ${darken(0.15, secondary)};
      }
    }
    @media (max-width: 960px) {
      width: 750px;
    }
    @media (max-width: 770px) {
      width: 100%;
      height: 60%;
      align-items: center;
      justify-content: center;
      margin: 0;
      background-color: ${transparentize(0.55, backgroundColor)};
      h1 {
        font-size: 2rem;
        text-align: center;
      }
    }
    @media (max-width: 280px) {
      a {
        width: 120px;
        font-size: 0.7rem;
      }
    }
  }
`;
