import { useNavigate } from "react-router-dom";

import { NotFoundCotnainer } from "./styles";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <NotFoundCotnainer>
      <title>Tudão chef's error</title>
      <h1>404</h1>
      <p>página não encontrada!</p>
      <button onClick={() => navigate("/")}>Voltar</button>
    </NotFoundCotnainer>
  );
};

export { NotFound };
