import imgBackground from "../../../assets/backgroundApresentation.png";
import imgBackgroundMobile from "../../../assets/backgroundApresentation2.png";
import { AlignSection } from "../../AlignSection";
import { ContentApresentation } from "./styles";

export function Apresentation() {
  const RenderMessage = () => {
    return (
      <div
        className="message-content"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <h1>Venha se deliciar com nossa diversidade de sabores!</h1>
        <a href="#about">Ver restaurante</a>
      </div>
    );
  };

  return (
    <AlignSection
      id="home"
      image={imgBackground}
      secondImage={imgBackgroundMobile}
    >
      <ContentApresentation>
        <RenderMessage />
      </ContentApresentation>
    </AlignSection>
  );
}
