import AOS from "aos";

import { AppRoutes } from "./routes";
import { GlobalStyles } from "./styles/globalStyles";

function App() {
  AOS.init();
  return (
    <>
      <AppRoutes />
      <GlobalStyles />
    </>
  );
}

export default App;
