import { transparentize } from "polished";
import styled from "styled-components";

const primary = "#000";

export const ContainerSlide = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media (max-width: 710px) {
    .desktop-selects {
      svg {
        display: none;
      }
    }
    flex-direction: column;
  }

  .image {
    width: 550px;
    height: 330px;
    border-radius: 1rem;
    border: 2px solid white;
    cursor: pointer;
    transition: 0.3s all;
    @media (max-width: 960px) {
      width: 450px;
      height: 300px;
    }
    @media (max-width: 710px) {
      width: 350px;
      height: 280px;
    }
    @media (max-width: 460px) {
      width: 260px;
      height: 210px;
    }
    @media (max-width: 400px) {
      width: 220px;
      height: 170px;
    }
    @media (max-height: 710px) {
      /* height: 260px; */
    }
  }

  .type_item {
    margin-top: -125px;
    position: absolute;
    width: 100%;
    padding: 1rem;
    height: 78px;
    background-color: ${transparentize(0.7, primary)};
    display: flex;
    justify-content: center;
    align-items: center;
  }
  span {
    text-align: center;
    font-weight: bold;
    font-size: 2rem;
    color: var(--white);
    transition: 0.3s all;
    @media (max-width: 710px) {
      font-size: 1.8rem;
    }
    @media (max-width: 460px) {
      font-size: 1.6rem;
    }
    @media (max-width: 400px) {
      font-size: 1.4rem;
    }
  }

  .desktop-selects {
    .right-arrow {
      margin-left: 1rem;
      font-size: 3rem;
      color: rgba(255, 255, 255, 0.502);
      z-index: 3;
      cursor: pointer;
      transition: 0.3s all;
      &:hover {
        color: white;
      }
    }

    .left-arrow {
      margin-right: 1rem;
      font-size: 3rem;
      color: rgba(255, 255, 255, 0.502);
      z-index: 3;
      cursor: pointer;
      user-select: none;
      transition: 0.3s all;
      &:hover {
        color: white;
      }
    }
  }

  .mobile-selects {
    margin-top: 1rem;
    width: 100px;
    align-items: center;
    justify-content: space-between;
    display: none;
    .mobile-right-arrow {
      font-size: 3rem;
      color: rgba(255, 255, 255, 0.502);
      z-index: 3;
      cursor: pointer;
      transition: 0.3s all;
      &:hover {
        color: white;
      }
    }

    .mobile-left-arrow {
      font-size: 3rem;
      color: rgba(255, 255, 255, 0.502);
      z-index: 3;
      cursor: pointer;
      user-select: none;
      transition: 0.3s all;
      &:hover {
        color: white;
      }
    }
    @media (max-width: 710px) {
      display: flex;
    }
  }

  .slide {
    opacity: 0.4;
    transition-duration: 1s ease;
    transform: scale(0.9);
  }

  .slide.active {
    opacity: 1;
    transition-duration: 0.4s;
    transform: scale(1);
  }
`;
