import { Dispatch, SetStateAction, useEffect } from "react";
import { IoClose } from "react-icons/io5";

import { Container } from "./styles";

interface IMenuMobileProps {
  menuIsVisible: boolean;
  setMenuIsVisible: Dispatch<SetStateAction<boolean>>;
}

export function MenuMobile({
  menuIsVisible,
  setMenuIsVisible,
}: IMenuMobileProps) {
  useEffect(() => {
    document.body.style.overflowY = menuIsVisible ? "hidden" : "auto";
  }, [menuIsVisible]);

  return (
    <Container isVisible={menuIsVisible}>
      <IoClose size={45} onClick={() => setMenuIsVisible(false)} />
      <nav>
        <a href="#home" onClick={() => setMenuIsVisible(false)}>
          Inicio
        </a>
        <a href="#menu" onClick={() => setMenuIsVisible(false)}>
          Cardápio
        </a>
        <a href="#about" onClick={() => setMenuIsVisible(false)}>
          Sobre
        </a>
        <a href="#address" onClick={() => setMenuIsVisible(false)}>
          Endereço
        </a>
        <a
          target="_blank"
          href="https://wa.me/553237217795?text=Gostaria%20de%20realizar%20meu%20pedido!"
          onClick={() => setMenuIsVisible(false)}
        >
          Fazer pedido
        </a>
      </nav>
    </Container>
  );
}
